<template>
  <div class="main">
    <div class="main__background" id="top">
        <img class="main__background--img" src="../../assets/textures/azul.png" alt="paint" />
    </div>
    <div class="main__header">
      <h2 class="main__header--title">RETRATO</h2>
    </div>
    <div class="main__gallery">
      <div class="gallery__content">
        <img class="gallery__content--img" v-for="(image, i) in pageOfItems" :src="image" :key="i" @click="index = i">
      </div>
    </div>
    <div class="main__pagination">
      <a href="#top">
        <jw-pagination :items="images" @changePage="onChangePage"></jw-pagination>
      </a>
    </div>
    <vue-gallery-slideshow :images="pageOfItems" :index="index" @close="index = null"></vue-gallery-slideshow>
    <div class="main__footer">
      <Footer></Footer>
    </div>
  </div>
</template>

<script>
import VueGallerySlideshow from 'vue-gallery-slideshow';
import Footer from '../../components/Footer.vue';

/**IMAGENES */
import R2 from '../../assets/images/Fotografía/Retrato/2.jpg';
import R1 from '../../assets/images/Fotografía/Retrato/1.jpg';
import R3 from '../../assets/images/Fotografía/Retrato/3.jpg';
import R4 from '../../assets/images/Fotografía/Retrato/4.jpg';
import R5 from '../../assets/images/Fotografía/Retrato/5.jpg';
import R6 from '../../assets/images/Fotografía/Retrato/6.jpg';
import R7 from '../../assets/images/Fotografía/Retrato/7.jpg';
import R8 from '../../assets/images/Fotografía/Retrato/8.jpg';
import R9 from '../../assets/images/Fotografía/Retrato/9.jpg';
import R10 from '../../assets/images/Fotografía/Retrato/10.jpg';
import R11 from '../../assets/images/Fotografía/Retrato/11.jpg';
import R12 from '../../assets/images/Fotografía/Retrato/12.jpg';
import R13 from '../../assets/images/Fotografía/Retrato/13.jpg';
import R14 from '../../assets/images/Fotografía/Retrato/14.jpg';
import R15 from '../../assets/images/Fotografía/Retrato/15.jpg';
import R16 from '../../assets/images/Fotografía/Retrato/16.jpg';
import R17 from '../../assets/images/Fotografía/Retrato/17.jpg';
import R18 from '../../assets/images/Fotografía/Retrato/18.jpg';
import R19 from '../../assets/images/Fotografía/Retrato/19.jpg';
import R20 from '../../assets/images/Fotografía/Retrato/20.jpg';
import R21 from '../../assets/images/Fotografía/Retrato/21.jpg';
import R22 from '../../assets/images/Fotografía/Retrato/22.jpg';
import R23 from '../../assets/images/Fotografía/Retrato/23.jpg';
import R24 from '../../assets/images/Fotografía/Retrato/24.jpg';
import R25 from '../../assets/images/Fotografía/Retrato/25.jpg';
import R26 from '../../assets/images/Fotografía/Retrato/26.jpg';
import R27 from '../../assets/images/Fotografía/Retrato/27.jpg';
import R28 from '../../assets/images/Fotografía/Retrato/28.jpg';
import R29 from '../../assets/images/Fotografía/Retrato/29.jpg';
import R30 from '../../assets/images/Fotografía/Retrato/30.jpg';
import R31 from '../../assets/images/Fotografía/Retrato/31.jpg';
import R32 from '../../assets/images/Fotografía/Retrato/32.jpg';
import R33 from '../../assets/images/Fotografía/Retrato/33.jpg';
import R34 from '../../assets/images/Fotografía/Retrato/34.jpg';
import R35 from '../../assets/images/Fotografía/Retrato/35.jpg';
import R36 from '../../assets/images/Fotografía/Retrato/36.jpg';
import R37 from '../../assets/images/Fotografía/Retrato/37.jpg';
import R38 from '../../assets/images/Fotografía/Retrato/38.jpg';
import R39 from '../../assets/images/Fotografía/Retrato/39.jpg';
import R40 from '../../assets/images/Fotografía/Retrato/40.jpg';
import R41 from '../../assets/images/Fotografía/Retrato/41.jpg';
import R42 from '../../assets/images/Fotografía/Retrato/42.jpg';
import R43 from '../../assets/images/Fotografía/Retrato/43.jpg';
import R44 from '../../assets/images/Fotografía/Retrato/44.jpg';
import R46 from '../../assets/images/Fotografía/Retrato/46.jpg';
import R47 from '../../assets/images/Fotografía/Retrato/47.jpg';
import R48 from '../../assets/images/Fotografía/Retrato/48.jpg';

export default {
    data () {
      return {
        active: 4,
        images: [
          R1,R2,R3,R4,R5,R6,R7,R8,R9,R10,
          R11,R12,R13,R14,R15,R16,R17,R18,R19,R20,
          R21,R22,R23,R24,R25,R26,R27,R28,R29,R30,
          R31,R32,R33,R34,R35,R36,R37,R38,R39,R40,
          R41,R42,R43,R44,R46,R47,R48
        ],
        pageOfItems: [],
        index: null
      }
    },
    methods: {
      onChangePage(pageOfItems) {
        this.pageOfItems = pageOfItems;
      }
    },
    components: {
      VueGallerySlideshow,
      Footer
    } 
}
</script>

<style scoped>
.main__background {
  position: relative;
}
.main__background--img {
  width: 100%;
  height: 570px;
  object-fit: cover;
}
.main__header {
  width: 100%;
  position: absolute;
  color: var(--txt-color);
  text-align: center;
  top: 250px;
  margin: auto;
}
.main__header--title{
  font-family: 'Yeseva One';
  font-size: 70px;
  padding: 9px 0px;
  margin: 2px;
}
.main__header--subtitle{
  font-family: var(--font);
  font-weight: 500;
  padding: 9px 0px;
  line-height: 28px;
  margin: 2px;
}
.main__pagination{
  margin-top: 50px;
  text-align: center;
}
.main__gallery{
  margin-top: -100px;
}
.gallery__content{
  position: relative;
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
}
.gallery__content--img{
  width: 500px;
  height: 500px;
  object-fit: cover;
  margin: 20px;
}
@media (max-width: 800px){
  .main__header--title{
    font-size: 40px;
  }
  .gallery__content--img {
    width: 200px;
    height: 200px;
  }
}
</style>